@import "../../../assests/scss/globals.scss";

.testimonial-box {
  margin-top: 8rem;
  margin-bottom: 4rem;
  font-family: Roboto;
}
.testimonial-carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
  // margin-bottom: rem;
}
.testimonial-carousal {
  max-width: 1000px;
  align-self: center;
}
.rec.rec-arrow {
  border-radius: 5px;
  cursor: pointer;
  background-color: #0d449a;
  border: transparent;
  color: white;
}

.content {
  display: block;
  width: auto;
  height: auto;
  margin-bottom: 30px;
  -webkit-box-align: initial;
  align-items: initial;
  border-bottom: initial;
  border-top-left-radius: initial;
  border-top-right-radius: initial;
  background-color: initial;
}

.row {
  margin-bottom: initial;
}

.arrow-prev {
  width: 50px;
  height: 50px;
  display: flex;
  align-self: center;
  position: relative;
  right: 6rem;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 7px;
  background-color: #0d449a;
  border: transparent;
  color: white;
  bottom: 2rem;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 1.6em;
  align-content: center;
  justify-content: center;
  box-shadow: 0 0 2px 0px #333;
}
.arrow-next {
  width: 50px;
  height: 50px;
  display: flex;
  padding-top: 7px;
  align-self: center;
  position: relative;
  left: 6rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: #0d449a;
  border: transparent;
  color: white;
  bottom: 5.2rem;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 1.6em;
  box-shadow: 0 0 2px 0px #333;
  align-content: center;
  justify-content: center;
}

.testimonial-description {
  text-align: center;
  margin-bottom: 2rem;
}

@media (max-width: 390px) {
  .testimonial-carousel-container {
    margin-bottom: 2rem;
  }
}

.testimonials-heading {
  font-weight: 700;
  color: #166dea;
  font-size: 28px;
  line-height: 33px;
  font-family: Roboto;
}
.testimonials-heading-desc {
  font-weight: 400;
  color: #031741;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.7;
  font-family: Roboto;
}

button.rec-dot {
  // background-color: white;
  box-shadow: 0 0 0 2px #6c6c6c;
  cursor: pointer;
}
button.rec-dot.rec-dot_active {
  cursor: pointer;

  background-color: #0d449a;
  box-shadow: 0 0 0 4px #0d449a;
}
button.rec-dot:hover:enabled {
  cursor: pointer;

  box-shadow: 0 0 0 3px #0d449a;
}
