.container {
    background-color: white;
    margin-top: 5%;
    width: 896px;
    text-align: center;
    margin-bottom: 12rem;
    max-width: 100%;
    .btn {
      padding: 1.5rem 4rem;
      cursor: pointer;
    }
  }
  
  .video {
    max-width: 100%;
    cursor: pointer;
    margin-bottom: 60px;
  }
  
  @media (max-width: 576px) {
    .container {
      width: 480px;
    }
  }
  @media (max-width: 320px) {
    .container {
      width: 280px;
    }
  }
  
  .about-heading {
    font-style: normal;
    font-weight: 700;
    color: #166dea;
    font-size: 28px;
    line-height: 33px;
    font-family: Roboto;
  }
  
  .about-desc-before-image {
    font-style: normal;
    font-weight: normal;
    color: #031741;
    opacity: 0.7;
    margin-bottom: 5rem;
    font-size: 16px;
    line-height: 20px;
    font-family: Roboto;
  }
  
  .about-desc-after-image {
    font-style: normal;
    font-family: Roboto;
    font-weight: normal;
    line-height: 20px;
    color: #6c6c6c;
    margin-bottom: 50px;
  }
  
  .dummy-container {
    display: grid;
    grid-template-columns: 187px 187px 187px 187px;
    grid-template-rows: 84px 84px;
    justify-content: center;
    align-content: start;
    margin-bottom: 40px;
    grid-gap: 5px;
    //   grid-gap: 5px;
    max-width: 100%;
  }
  .dummyitem {
    cursor: pointer;
  }
  
  @media (max-width: 790px) {
    .dummy-container {
      // grid-gap: 2rem;
      grid-template-columns: 170px 170px;
      grid-template-rows: 84px 84px 84px 84px;
    }
  }
  @media (max-width: 400px) {
    .dummy-container {
      grid-template-columns: 137px 137px;
      grid-template-rows: 84px 84px 84px 84px;
      grid-row-gap: 5px;
    }
  }
  @media (max-width: 290px) {
    .dummy-container {
      grid-gap: 5px;
      grid-template-columns: 80px 80px;
      grid-template-rows: 50px 50px 50px 50px;
      grid-row-gap: 5px;
    }
  }
  
  $container-max-widths: (
    xs: 0px,
    sm: 289px,
    md: 440px,
    lg: 780px,
    xl: 1366px,
  );
  
  $grid-breakpoints: (
    xs: 0,
    sm: 320px,
    md: 760px,
    lg: 960px,
    xl: 1366px,
    xxl: 1900px,
  );