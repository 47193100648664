@import "../../../../assests/scss/globals.scss";

.form {
  min-width: 60%;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

iframe > * {
  width: 70vw;
  min-height: 500px;
}
