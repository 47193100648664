@import "../../../../assests/scss/globals.scss";

.Microsoft {
  padding: 3rem 7vw;
  display: flex;
  flex-wrap: wrap;
  color: $shade-white;

  &-left {
    width: 50%;
    @include respond-phone {
      width: 100%;
    }
  }
  &-right {
    padding: 3rem 0 0 1rem;
    width: 50%;

    &-heading {
      font-size: 1.75rem;
      margin: 1rem 0;
    }
    @include respond-phone {
      padding: 0 0 0 0;
      text-align: center;
      width: 100%;
    }
  }
}

.MicrosoftCard {
  padding: 1rem 0;
  width: 80%;

  @include respond-tablet {
    width: 100%;
  }

  img {
    max-width: 90%;
    height: auto;
  }
}
