@import "../../../assests/scss/globals.scss";
/* Certificate Main Component Header */

.CertificatePageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-program-btn {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #166dea;
  color: #ffffff;
  padding: 15px 24px;
  border-radius: 5px;

  &:hover {
    color: #ffffff;
    opacity: 0.9;
    cursor: pointer;
  }
}

/* Modify Modal */
.certificate-modal {
  display: none;
  position: absolute;
  max-width: 25px;
  z-index: 2000;
}

.is-visible {
  display: block;
  position: absolute;
  inset: 0px auto auto 0px;
}

.issue-header {
  display: flex;
  align-items: center;
  gap: 2%;
  margin-bottom: 30px;
}
