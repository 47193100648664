@import "../../assests/scss/globals.scss";

.allcourses {
  padding-top: 2rem;
  margin-top: 15vh;
  min-height: 100vh;

  &-search {
    width: 50%;
    margin-left: auto;
    padding: 0 7.5vw;

    @include respond-tablet {
      width: 70%;
    }
    @include respond-phone {
      width: 100%;
    }
  }

  &-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    &-left {
      width: 25%;
      @include respond-phone {
        width: 100%;
      }
    }
    &-right {
      width: 75%;

      @include respond-phone {
        width: 100%;
      }
    }
  }
}

///////////////////////////
////
.SearchBar {
  position: relative;

  &-icon {
    position: absolute;
    top: 50%;
    left: 0.8rem;
    transform: translateY(-50%);
  }

  input {
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    width: 100%;
    border: 1px solid #1a1a1a;
    border-radius: 0.3rem;
    color: #acacac;
  }
}

////////////////////////////////
/////////////////////////

.btn-prev {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: #0d449a;
  border: none;
  color: $shade-white;
  font-size: 1.6em;
  box-shadow: 0 0 2px 0px #333;
}
.btn-next {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: #0d449a;
  border: none;
  color: $shade-white;
  font-size: 1.6em;
  box-shadow: 0 0 2px 0px #333;
}

//////////////////////////
///////

.SideBar {
  width: 100%;
  padding: 2rem;

  &-large {
    display: block;

    @include respond-phone {
      display: none;
    }
  }
  &-small {
    display: none;
    align-items: center;

    @include respond-phone {
      display: block;
      display: flex;
      justify-content: space-between;
    }
    @include respond-xs {
      display: block;
      display: flex;
      flex-direction: column;
    }
  }

  &-left {
    width: 45%;

    @include respond-xs {
      width: 90%;
      align-self: center;
    }

    &-links {
      display: flex;
      flex-direction: column;
      border: 1px solid $shade-grey-1;
      border-radius: 0.4rem;
      padding: 0.5rem;

      a {
        color: color;
      }
    }
  }
  &-right {
    width: 45%;

    @include respond-xs {
      margin-top: 1rem;
      width: 90%;
      align-self: center;
    }
  }
  &-header {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $shade-grey-1;
    border-radius: 0.4rem;
    color: $color-primary;
  }

  &-top {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    &-title {
      color: $color-primary;
      margin-bottom: 1rem;
    }

    &-links {
      display: flex;
      flex-direction: column;
      a {
        padding: 0.7rem;
        text-decoration: none;
        color: $color-primary;
      }
    }
  }
  &-selected {
    background-color: $color-accent-1;
    color: $shade-white !important;
  }
  &-selected:hover {
    background-color: $color-accent-1;
    color: $shade-white !important;
  }

  &-level {
    display: flex;
    flex-direction: column;

    &-title {
      color: $color-primary;
      margin-bottom: 1rem;
    }

    &-links {
      display: flex;
      flex-direction: column;

      .active {
        background-color: $color-accent-1;
        color: $shade-white;
      }
      &-link {
        cursor: pointer;
        padding: 0.7rem;
        text-decoration: none;
        color: $color-primary;
      }
    }
  }
}

.popularcourses {
  margin: 2rem 0;
  padding: 1rem;
  position: relative;

  &-header {
    margin: 2rem 0;
    width: 100%;
    padding-right: 7vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-xs {
      flex-direction: column;
      align-items: flex-start;
    }

    &-title {
      color: $color-primary;
    }
    &-line {
      flex: 1;
      padding: 0 4rem;

      hr {
        margin-top: 5px;
        margin-bottom: 5px;
        border: none;
        border-bottom: 2px solid #bfbfbf;
      }

      @include respond-phone {
        display: none;
      }
    }
    &-buttons {
      display: flex;
      button {
        margin-left: 1rem;

        @include respond-xs {
          margin-left: 0;
          margin-right: 1rem;
        }
      }
    }
  }

  &-cards {
    display: flex;
    overflow-x: hidden;
    padding: 1rem 0;

    .CourseCard {
      margin-right: 2rem;
    }
  }
}

.blogcard {
  display: flex;
  margin: 3rem 0 2rem 0;

  @include respond-tablet {
    flex-direction: column;
  }
  &-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-right {
    margin-left: 3rem;
    margin-right: 7vw;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;

    @include respond-tablet {
      justify-content: center;
      align-items: center;
    }
    @include respond-phone {
      width: 100%;
    }

    &-title {
      font-size: 1.75rem;
      margin-bottom: 1rem;
    }
    &-para {
      height: 80px;
      overflow: hidden;
      position: relative;
      margin-bottom: 1rem;
    }
    &-blur {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 10;
    }
    &-rfa {
      color: $color-primary;
      margin-bottom: 1rem;
      align-self: flex-start;
    }
    &-tags {
      display: flex;
      flex-wrap: wrap;
      &-tag {
        color: $shade-white;
        padding: 0.2rem 0.8rem;
        border-radius: 50px;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
      }
    }
  }
}

.All {
  &-cards {
    margin-right: 7vw;
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @include respond-xs {
      margin: 0 auto;
    }
  }
}
