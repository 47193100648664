@import "../../../assests/scss/globals.scss";

.quiz-nav {
  color: $shade-grey-3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quiz-container {
  padding: 0 3rem;
  @include respond-xs {
    padding: 0 5px;
  }
  @include respond-phone {
    padding: 0 1rem;
  }
  @include respond-tablet {
    padding: 0 2rem;
  }
}

.quiz-body {
  min-height: 90vh;
}
.quiz-card-conatiner {
  background-color: white;
  padding-top: 4rem;
  padding-bottom: 4rem;
  // margin: 2rem 0;
}

// Quiz Upload Button
.quiz-upload-btn {
  position: relative;
  height: 110px;
  padding: 16px;
  // Styling
  border-radius: 8px;
  border: 1px solid $shade-grey-1;
  color: $color-secondary-2;
  font-weight: 500;
  cursor: pointer !important;
  // Alignment
  display: inline-flex;
  flex-shrink: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  &-selected {
    outline: 0.1rem solid $color-button;
    background-color: $color-secondary-1;
    // max-width: 100px;
    // max-height: 100px;
  }
}
.quiz-upload-btn input[type="file"] {
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 0;
  width: 100%;
  cursor: pointer !important;
}

.download-text {
  color: $color-button;
  font-size: 0.7rem;
  font-weight: 700;
  cursor: pointer;
}
