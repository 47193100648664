@import "../../../assests/scss/globals.scss";

.sidebar-container {
  display: flex;
  flex-direction: column;
  overflow: visible;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  width: 346px;
  background-color: #031741;

  @include respond-tablet {
    width: 150px;
  }
  @include respond-phone {
    display: none;
  }
}

.logo-container {
  height: 76px;
  display: flex;
  justify-self: center;
  align-items: center;
  overflow: visible;
  @include respond-tablet {
    width: 150px;
    padding: 0 1rem;
  }
  img {
    width: 100%;
  }
}

.sidebar-list {
  padding-top: 2.5rem;
  width: 100%;

  &-item {
    padding: 8px 1rem;
    width: 100%;
    // height: 36px;

    display: grid;
    grid-template-columns: 1fr 11fr;
    gap: 0.5rem;

    text-overflow: hidden;
    justify-content: start;
    align-items: center;

    transition: all 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #166dea;
    }
    &-selected {
      background-color: #166dea;
    }
  }
}
