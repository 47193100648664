@import "../../assests/scss/globals.scss";

.ournetwork {
  width: 100%;

  @include respond-tablet {
    transform: translateY(-10vh);
  }
  @include respond-phone {
    transform: translateY(-13vh);
  }

  &-header {
    text-align: center;
    &-title {
      color: $color-accent-2;
      font-size: 1.75rem;
    }
    &-desc {
      margin: auto;
      width: 80%;
      color: rgba($color-primary, 0.7);
    }
  }
  &-imgs {
    padding: 2rem 7vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @include respond-phone {
      padding: 2rem 7vw 0 7vw;
    }

    &-img {
      width: 20%;
      display: flex;
      justify-content: center;

      @include respond-xs {
        width: 30%;
      }

      img {
        cursor: pointer;
        filter: grayscale(100%);
        max-width: 95%;
        @include respond-tablet {
          filter: grayscale(0%);
        }
      }
      img:hover {
        filter: grayscale(0%);
      }
    }

    // img {
    //   //   max-width: 15%;
    //   //   height: auto;
    //   //   max-height: 50px;
    //   //   padding: 0 2rem;
    //   max-width: 20vw;

    //   @include respond-xs {
    //     flex-basis: 35%;
    //   }
    // }
  }
}
