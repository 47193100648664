@import "../assests/scss/globals.scss";

a,
a:hover,
a:focus,
a:active,
a:link,
a:visited {
  text-decoration: none;
  color: inherit;
}

.icon {
  margin-right: 0.5rem;
  display: inline-block;
}
.cursor-icon {
  cursor: pointer;
}

.flex-center {
  display: flex;
  justify-content: center;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-align-start {
  display: flex;
  align-items: flex-start;
}

.flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}

.admin-dashboard-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.dashboard-main-container {
  width: 100%;
  padding: 0 65px;

  @include respond-tablet {
    padding: 0 5px;
  }
}

/* NavBar */
.admin-navbar {
  height: 76px;
  background-color: #031741;
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 2rem;
  @include respond-tablet {
    height: auto;
  }
}

/* ProfileInfo */

.profile-info-container {
  display: flex;
  align-items: center;
  min-width: 149px;
  height: 52px;
  margin: 12px 0;
}

.avatar {
  border: 1px solid #a8a8a8;
  border-radius: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  margin: 0 12px;
}

.avatar-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
}
.avatar-role {
  opacity: 0.7;
}

.active-breadcrumbs {
  color: #166dea;
  font-weight: bold;
}

// Checkbox
.checkbox-input {
  width: 1.3em;
  height: 1.3em;
  border-radius: 10px;
  vertical-align: middle;
  // appearance: none;
  // -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

// Input Field
.input-field {
  // max-width: 800px;
  // width: 100%;
  flex-grow: 1;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  border: 2px solid $shade-grey-1;
}

.input-field:focus .input-field:active {
  border-color: $shade-grey-3 !important;
}

// round-checkbox
.round-checkbox {
  position: relative;
  height: 28px;
  width: 28px;
}

.round-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.round-checkbox input[type="checkbox"]:checked + label {
  background-color: $color-accent-2;
  border-color: $color-accent-2;
}

.round-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
