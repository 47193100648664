@import "../../../../assests/scss/globals.scss";

.program-card {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  &:hover {
    background-color: #ecf4ff;
    .view-btn {
      color: #0952bc;
      border-color: #0952bc;
    }
  }
}

.view-btn {
  flex-grow: 1;
  max-width: 170px;
  color: transparent;
  border-color: transparent;
}

.card-main {
  flex-grow: 6;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #c4c4c4;
  padding: 10px;
  padding-left: 0px;
}

.card-view {
  flex-grow: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}

.card-details {
  flex-grow: 4;
  display: flex;
  align-items: center;
}

.card-stats {
  flex-grow: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.stats-btn {
  padding: 0.5rem;
  border: 1px solid transparent;
  &:hover {
    border-color: #166dea;
  }
}
