@import "../../../assests/scss/globals.scss";

/*  SearchBar */
.searchbar {
  flex-grow: 1;
  max-width: 600px;
  background-color: #ffffff;
  color: #b5b5b5;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  outline: 2px solid #b5b5b5;
  border-radius: 23px;
  overflow: none;
}

.search-input {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
}
