@import "../../../assests/scss/globals.scss";
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1;
  padding: 25px;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  width: 500px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-close {
  display: flex;
  justify-content: end;
  margin: 1rem;
  margin-bottom: 0;
  border-bottom: 0px;
}
.modal-header {
  margin-bottom: 25px;
  padding: 0px;
  border-bottom: 0px;
}

.modal-body {
  padding: 25px;
}

.modal-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  // flex-grow: 1;
  align-items: center;
  margin: 10px 0;
}
