@import "../../../../assests/scss/globals.scss";

.KeyHighlights {
  padding: 2rem 7vw;

  @include respond-tablet {
    padding: 0 7vw 3rem 7vw;
  }

  &-header {
    text-align: center;

    &-title {
      color: $color-accent-2;
      font-size: 1.75rem;
    }
    &-subtitle {
      color: $color-primary;
    }
  }

  &-cards {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    &-card {
      margin: 1rem;
      display: flex;
      width: 30%;
      align-items: center;

      @include respond-tablet {
        width: 40%;
      }
      @include respond-phone {
        width: 80%;
        margin: 0;
      }
      @include respond-xs {
        width: 100%;
        margin: 0;
      }

      &-logo > * {
        @include respond-phone {
          width: 4rem;
        }
      }

      &-desc {
        margin-left: 1rem;
        &-highlight {
          font-weight: 600;
          color: $color-accent-1;
        }
      }
    }
  }
}
