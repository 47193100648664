
.about{
  &-container{
    padding: 0;
    margin: 0;
    width: 100vw;
    height: calc(100vw * (3935/1366));
    background-image: url('../../assests/images/background_about.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    display: flex;
    flex-direction: column;

  // width: 1366px;
  // height: 4393px;
}

  &-top{
    width: 381px;
    height: auto;
    margin-top: 17.5%;
    margin-left: 14%;
    &-header{
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 47px;
      color: #0D449A;
    }
    &-divider{
        width: 228px;
        margin-top: -10px;
        margin-bottom: 16px;
      }
    &-text{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #414141;
      margin: 16px 0px;
    }
    &-button{
      width: 183px;
      height: 59px;
      background: #0952BC;
      border-radius: 5px;
      margin: 16px 0px;
      padding: 20px 40px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      cursor: pointer;
      text-transform: uppercase;
      color: #FFFFFF;
    }
    &-button:hover{
      color: #FFFFFF;
    }
  }

  &-origin{
    width: 535px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 21%;
    margin-left: 52%;
    &-header{
      width: 129px;
      display: flex;
      flex-direction: row;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      &-blue{
        margin-left: 10px;
        color: #156dea;
      }
      &-black{
        color: #031741;
      }
    }
    &-divider{
      width: 228px;
      margin-top: -2px;
      margin-bottom: 16px;
    }

    &-text{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #414141;
      margin: 16px 0px;
    }
  }

  &-vision{
    width: 381px;
    height: 131px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 25%;
    margin-left: 10.5%;

    &-header{
      width: 129px;
      display: flex;
      flex-direction: row;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      &-blue{
        margin-left: 10px;
        color: #156dea;
      }
      &-black{
        color: #031741;
      }
    }

    &-divider{
      width: 228px;
      margin-top: -2px;
      margin-bottom: 16px;
    }

    &-text{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #414141;
      margin: 16px 0px;
    }
  }

  &-mission{
    width: 530px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 75vh;
    @media screen and (max-width: 1440px){
      margin-top: 40vw;
      
    }
    margin-left: 53%;
    &-header{
      width: 129px;
      display: flex;
      flex-direction: row;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      &-blue{
        margin-left: 10px;
        color: #156dea;
      }
      &-black{
        color: #031741;
      }
    }

    &-divider{
      width: 228px;
      margin-top: -2px;
      margin-bottom: 16px;
    }

    &-text{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #414141;
      margin: 16px 0px;
    }
  }

    &-chose{
    width: 100vw;
    height: 80vh;
    background: #ECF4FF;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1920px){
      margin-top: 31vw;
    }
    @media screen and (max-width: 1600px){
      margin-top: 16vh;
      height: 20vw;
    }
    @media screen and (max-width: 1440px){
      margin-top: 20vh;
    }
    @media screen and (max-width: 1280px){
      margin-top: 0;
    }

    &-header{
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      text-align: center;
      color: #031741;
      margin-top: 60px;
    }

    &-subheader{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #0952BC;
      opacity: 0.7;
      margin-top: -5px;
    }

    &-container{
      width: 1146px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 200px auto;
      &-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        margin: 0 45px;
        width: 231px;

        &-image{
          width: 114px;
          height: 100px;
        }
        &-header{
          width: max-content;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          text-transform: uppercase;
          color: #031741;
          margin-top: 40px;
          margin-bottom: 10px;
        }
        &-text{
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #0952BC;
          opacity: 0.8;
          width: 221px;
        }
      }
    }
  }
    &-community{
      width: 100vw;
      height: 30vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20vh;
      @media screen and (max-width: 1920px){
        margin-top: -45vw;
      }
      @media screen and (max-width: 1440px){
        margin-top: -40vw;
      }
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        color: #0952BC;
      }
      &-subheader{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #031741;
        opacity: 0.5;
        margin-top: -5px;
      }
      &-container{
        width: 1096px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 50px;
        flex-wrap: wrap;
        margin-bottom: 100px;
      }
    }

}

// media queries for screen less than 960px
@media screen and (max-width: 960px){
  .about{
    &-container{
      height: calc(100vw * (4342/960));
      background-image: url('../../assests/images//background_about_960.png');
      background-size: 100% 100%;
    }
  }
  .about-origin{
    width: 353px;
    margin-top: 14%;
    margin-left: 53%;
    
    &-text{
      line-height: 19px;
      margin: 0;
    }
  }
  .about-vision{
    width: 373px;
    margin-top: 18%;
    margin-left: 6%;
    &-text{
      line-height: 19px;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
  .about-mission{
    width: 370px;
    margin-top: 33%;
    margin-left: 60%;
    &-text{
      line-height: 19px;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
  .about-chose{
    margin-top: 24%;
    height: auto;
    &-subheader{
      margin-bottom: 100px;
    }
    &-container{
      width: 100%;
      margin-bottom: 100px;
      flex-wrap: wrap;
      &-card{
        padding: 20px;
      }
    }
    
  }
  .about-community{
    &-container{
      width: 100%;
      &-image{
        padding: 20px;
      }
    }
  }
}

@media screen and (max-width: 480px){
  .about{
    &-container{
      height: 4727px;
      background-image: url('../../assests/images/background_about_480.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      overflow-y: hidden;
    }
    &-top{
      width: 375px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      // @media screen and (max-width: 415px){
      //   margin: auto;
      //   margin-top: 17.5%;
      // }
      &-text{
        font-size: 16px;
        line-height: 19px;  
        text-align: center;
        margin: 0;
      }
    }
  }
  .about-origin{
    width: 375px;
    margin: 0 auto;
    margin-top: 431px;
    align-items: center;
    &-header{
      width: auto;
      margin-top: -5px;
      margin-bottom: 350px;
      text-align: center;
      // @media screen and (max-width: 415px){
      //   margin-top: -15%;
      //   margin-bottom: 290px;
      // }
    }
    &-divider{
      visibility: hidden;
    }
    &-text{
      line-height: 19px;
      margin: 0;
      text-align: center;
    }
  }
  .about-vision{
    width: 375px;
    margin: 0 auto;
    margin-top: 144px;
    align-items: center;
    @media screen and (max-width: 415px){
      width: 375px;
    }
    &-header{
      width: auto;
      margin-top: -25px;
      margin-bottom: 310px;
      text-align: center;

      // @media screen and (max-width: 415px){
      //   margin-top: -27%;
      // }
    }
    &-divider{
      visibility: hidden;
    }
    &-text{
      width: 375px;
      line-height: 19px;
      margin: 0;
      text-align: center;
      // @media screen and (max-width: 415px){
      //   margin-top: -10%;
      // }
    }
  }
  .about-mission{
    width: 375px;
    align-items: center;
    margin: 0 auto;
    margin-top: 546px;
    // @media screen and (max-width: 415px){
    //   margin-top: 86%;
    //   width: 90vw;
    // }
    &-header{
      width: auto;
      margin-top: -25px;
      margin-bottom: 520px;
      text-align: center;
    }
    &-divider{
      visibility: hidden;
    }
    &-text{
      width: 375px;
      line-height: 19px;
      margin: 0;
      text-align: center;
    }
  }
  .about-chose{
    flex-wrap: wrap;
    margin-top: 144px;
    height: auto;
    // @media screen and (max-width: 415px){
    //   width: 100vw;
    //   margin: auto;
    // }
    &-header{
      width: 375px;
      @media screen and (max-width: 415px){
        width: 375px;
      }
      text-align: center;
      word-wrap: break-word;
      margin: auto;
      margin-top: 40px;
      margin-bottom: 10px;
    }
    &-subheader{
      width: 375px;
      @media screen and (max-width: 415px){
        width: 375px;
      }
      text-align: center;
      word-wrap: break-word;
      margin: auto;
      margin-bottom: 40px;
    }
    &-container{
      width: 375px;
      &-card{
        padding: 10px;
        margin:10px;
      }
    }
  }
  .about-community{
    margin-top: 72px;
    margin-bottom: 10px;
    height: max-content;
    &-container{
      width: 375px;
      &-image{
        padding: 10px;
        margin: 10px;
      }
    }
  }
}
@media screen and (max-width: 320px){
  .about{
    &-container{
      height: calc(100vw * (5284/320));
      background-image: url('../../assests/images/background_about_360.png');
      background-size: 100% 100%;
      overflow-y: hidden;
    }
    &-top{
      width: 280px;
      margin:0 auto;
      margin-top: 29%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-divider{
        visibility: hidden;
        margin: 0;
      }
      &-text{
        font-size: 16px;
        line-height: 19px;  
        text-align: center;
        margin: 0;
      }
    }
  }
  .about-origin{
    width: 278px;
    margin: 0 auto;
    margin-top: 94%;
    align-items: center;
    &-header{
      width: auto;
      margin-bottom: 320px;
      text-align: center;
    }
    &-divider{
      visibility: hidden;
    }
    &-text{
      line-height: 19px;
      margin: 0;
      text-align: center;
    }
  }
  .about-vision{
    width: 278px;
    margin: 0 auto;
    margin-top: 20%;
    align-items: center;
    &-header{
      width: auto;
      margin-bottom: 320px;
      text-align: center;
    }
    &-divider{
      visibility: hidden;
    }
    &-text{
      width: auto;
      line-height: 19px;
      margin: 0;
      text-align: center;
    }
  }
  .about-mission{
    width: 100%;
    align-items: center;
    margin: 0 auto;
    margin-top: 178%;
    &-header{
      width: auto;
      margin-bottom: 510px;
      text-align: center;
    }
    &-divider{
      visibility: hidden;
    }
    &-text{
      width: 278px;
      line-height: 19px;
      margin: 0;
      text-align: center;
    }
  }
  .about-chose{
    flex-wrap: wrap;
    margin-top: 10%;
    height: auto;
    &-header{
      width: 288px;
      text-align: center;
      word-wrap: break-word;
      margin: auto;
      margin-top: 40px;
      margin-bottom: 5px;
    }
    &-subheader{
      width: 288px;
      text-align: center;
      word-wrap: break-word;
      margin: auto;
      margin-bottom: 40px;
    }

    &-container{
      width: 100%;
      &-card{
        padding: 30px;
        margin:10px;
      }
    }
  }
  .about-community{
    width: 288px;
    &-container{
      width: 100%;
      &-image{
        padding: 0;
        margin-left: 23px; ;
      }
    }
  }
}
