@import "../../../../assests/scss/globals.scss";

// .program-card {
//   background-color: #ffffff;
//   display: flex;
//   align-items: center;
//   padding: 0 20px;
//   padding-top: 10px;
//   padding-bottom: 5px;
//   &:hover {
//     background-color: #ecf4ff;
//     .view-btn {
//       color: #0952bc;
//       border-color: #0952bc;
//     }
//   }
// }

.program-view-card {
  position: relative;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  align-items: center;
  padding: 0 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  // border-bottom: 1px solid #c4c4c4;
  &:hover {
    background-color: #ecf4ff;
    .hover-visible {
      visibility: visible;
    }
    .view-btn {
      color: #0952bc;
      border-color: #0952bc;
    }
  }
  &::after {
    content: "";
    width: 80%;
    height: 1px;
    background-color: #c4c4c4;
    position: absolute;
    bottom: 5px;
    left: 0;
  }
}
.hover-visible {
  visibility: hidden;
}

.view-btn {
  flex-grow: 1;
  max-width: 170px;
  color: transparent;
  border-color: transparent;
}

.card-main {
  flex-grow: 6;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #c4c4c4;
  padding: 10px;
  padding-left: 0px;
}

.card-view {
  flex-grow: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}

.card-details {
  flex-grow: 4;
  display: flex;
  align-items: center;
}

.card-view-stats {
  flex-grow: 4;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.stats-btn {
  padding: 0.5rem;
  border: 1px solid transparent;
  &:hover {
    border-color: #166dea;
  }
}
