/* Footer CSS */
.footer {
  color: white;
  background: #000000;
  padding: 60px 20px 20px 20px;
}

.footer-row {
  padding: 0px;
  color: white;
  margin: 0 13% 0 13%;
  background: #000000;
}

@media (max-width: 380px) {
  .footer-row {
    margin-left: 0px;
  }
}

.footerCard {
  /* border: 1px solid white; */
  color: white;
  text-align: left;
  line-height: normal;
  margin-bottom: 20px;
}

#social-heading {
  margin: 0px 0px 17px 0px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
}

.footer-link {
  color: white;
  display: block;
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  margin-bottom: 1rem;
  opacity: 0.6;
}

.footer-heading {
  margin: 0px 0px 17px 0px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
}

.footer-link:hover {
  opacity: 1;
  border-bottom: 1px black;
  color: white;
  text-decoration: underline;
  text-decoration-color: black;
}

/* Footer Images */

.startUpImage {
  margin-top: 30px;
  cursor: pointer;
  width: 100%;
  height: auto;
  max-width: 200px;
}

.foxmulaLogoFooter {
  margin-bottom: 30px;
  max-width: 100%;
  cursor: pointer;
  height: auto;
}

.social-logos {
  margin: 5px;
  max-width: 100%;
  cursor: pointer;
  height: auto;
}

.margin-neg {
  margin-bottom: -10px;
}

@media (max-width: 480px) {
  .footer-link {
    color: white;
    display: block;
    cursor: pointer;
    text-decoration: none;
    margin: 0px;
    margin-bottom: 1rem;
    opacity: 1;
    border-bottom: 1px black;
    text-decoration-color: black;
  }

  .margin-neg {
    margin-bottom: -10px;
  }
}