@import "../../assests/scss/globals.scss";

.CourseCard {
  width: 293px;
  background-color: $shade-white;
  display: flex;
  flex-direction: column;

  &-img {
    position: relative;

    &-enrolled {
      position: absolute;
      top: 0.5rem;
      background-color: $shade-black-50;
      color: $shade-white;
      padding: 0.3rem;
      display: flex;
      align-items: center;
    }

    &-trending {
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      color: $shade-white;
      padding: 0.2rem 0.5rem;
      border-radius: 50px;
      z-index: 50;
    }

    // img {
    //   width: 293px;
    //   height: auto;
    // }
  }

  &-desc {
    flex: 1;
    width: 293px;
    padding: 0.7rem;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-title {
      text-transform: uppercase;
      margin: 0.3rem 0;
    }

    &-subtitle {
      text-transform: uppercase;
      color: $color-accent-2;
    }
  }
}