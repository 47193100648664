/////////////////////////////////
//COLOR VARIABLES (to be used with scss)

$color-primary: #031741;
$color-accent-1: #0d449a;
$color-accent-2: #166dea;

$color-button: #0952bc;

$color-secondary-1: #ecf4ff;
$color-secondary-2: #6c6c6c;

$shade-white: #ffffff;
$shade-grey-1: #c4c4c4;
$shade-grey-2: #a8a8a8;
$shade-grey-3: #414141;
$shade-grey-4: #464646;

$shade-black: #000000;
$shade-black-50: rgba(#000000, 0.5);

/////////////////////////////////
//COLOR CLASSES (to be used with jsx)

.color-primary {
  background-color: #031741;
}

.color-accent-1 {
  background-color: #0d449a;
}

.color-accent-2 {
  background-color: #166dea;
}

.color-secondary-1 {
  background-color: #ecf4ff;
}

.color-secondary-2 {
  background-color: #6c6c6c;
}

.shade-white {
  background-color: #ffffff;
}

.shade-grey-1 {
  background-color: #c4c4c4;
}

.shade-grey-2 {
  background-color: #a8a8a8;
}

.shade-grey-3 {
  background-color: #414141;
}

.shade-black {
  background-color: #000000;
}

.shade-black-50 {
  background-color: rgba(#000000, 0.5);
}

/////////////////////////////////
//TEXT CLASSES (to be used with jsx)

.text-primary {
  color: #031741;
}

.text-accent-1 {
  color: #0d449a;
}

.text-accent-2 {
  color: #166dea;
}

.text-secondary-1 {
  color: #ecf4ff;
}

.text-secondary-2 {
  color: #6c6c6c;
}

.text-shade-white {
  color: #ffffff;
}

.text-shade-grey-1 {
  color: #c4c4c4;
}

.text-shade-grey-2 {
  color: #a8a8a8;
}

.text-shade-grey-3 {
  color: #414141;
}

.text-shade-black {
  color: #000000;
}

.text-shade-black-50 {
  color: rgba(#000000, 0.5);
}

.text-bold {
  font-weight: 700;
}

.text-medium {
  font-weight: 500;
}

.text-regular {
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}

/////////////////////////////////
//RESPONSIVE MIXINS (to be used for responsive design)

@mixin respond-xs {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin respond-phone {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin respond-tablet {
  @media (max-width: 960px) {
    @content;
  }
}

.view-port {
  margin-top: 15vh;
  height: 100vh;
}

html,
body {
  overflow-x: hidden;
}