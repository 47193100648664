@import "../../../../assests/scss/globals.scss";

.HowDoesItWork {
  padding: 3rem 7vw;

  @include respond-phone {
    margin-bottom: 5rem;
  }

  &-header {
    text-align: center;

    &-title {
      color: $color-accent-2;
      font-size: 1.75rem;
    }
    &-subtitle {
      color: $color-primary;
    }
  }
  &-container {
    display: flex;

    &-left {
      display: flex;
      align-items: center;
      width: 40%;
      img {
        width: 80%;
        height: auto;
      }
      @include respond-phone {
        display: none;
      }
    }
    &-right {
      width: 60%;
      padding: 5rem 0;
      @include respond-phone {
        width: 100%;
        padding: 2rem 0;
      }
    }
  }
}

.worktable {
  width: 100%;

  &-header {
    margin: 0.5rem 0;
    color: $shade-grey-4;
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 3px 4px 17px rgba(0, 0, 0, 0.1);

    img {
      cursor: pointer;
    }
  }
  &-desc {
    padding: 2rem 1.5rem;
    color: $shade-grey-4;
    .btn {
      margin-top: 1rem;
    }
  }
}
