.card {
  max-width: 456px;
  max-height: auto;
  background: linear-gradient(104.79deg, #ECF4FF 3.48%, #FCFDFF 97.56%);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 24px;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}

.content {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;

  line-height: 137.19%;
  color: #414141;
}

.img-container {
  left: 0%;
  right: 0%;
  cursor: pointer;

  top: 0%;
  bottom: 0%;
  border-radius: 10px;
}

.user-details {
  display: flex;
  cursor: pointer;

  flex-direction: row;
  align-items: center;
}

.user-name {
  height: 19px;
  font-weight: bold;
  font-size: 16px;
  color: #166dea;
}

.designation {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
