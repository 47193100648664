@import "../../../../assests/scss/globals.scss";

.section-heading {
  margin-top: 3rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
// .horizontal-line {
//   margin-top: 38px;
//   padding: 0;
//   border-bottom: 3px solid $shade-grey-1;
//   border-radius: 100px;
//   flex-grow: 1;
//   height: 0px;
// }

// File / Image Uplaod Button
.file-upload-btn {
  position: relative;
  max-height: 150px;
  padding: 1.8rem;
  // Styling
  border: 2px dashed $color-accent-2;
  color: $color-accent-2;
  cursor: pointer !important;
  // Alignment
  display: inline-flex;
  flex-shrink: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  &-selected {
    border: 2px solid $color-accent-2;
    // max-width: 100px;
    max-height: 100px;
  }
}
.file-upload-btn input[type="file"] {
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 0;
  width: 100%;
  cursor: pointer !important;
}

.section-two-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;
}

.feature-input-card {
  display: flex;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  margin: 2rem 0;
}

.compitator-container {
  display: flex;
  gap: 2rem;
  overflow-x: scroll;
  max-width: 60vw;
  padding-bottom: 0.7rem;
  position: absolute;
  top: 0;
  margin-left: 12rem;
  transition: width 1s;
}
.compitator-card {
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 1s;

  .compitator-checkbox-container {
    height: 50px;
    display: flex;
    align-items: center;
  }
}

.feature-input-container {
  position: relative;

  input {
    height: 50px;

    outline: 0;
  }
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #c4c4c4;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
}
