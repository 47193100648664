.faq{
    &-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &-header{
        width: 100vw;
        height: calc(100vw * (583/1366));
        @media screen and (max-width: 480px){
            height: 95vw;
            background-color: #ECF4FF;
            background-size: 100% calc(100vw * (583/1366));
        }
        background-image: url('../../../assests/images/faq_background.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        &-text{
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 54px;
            line-height: 63px;
            @media screen and (max-width: 960px){
                font-size: 40px;
                line-height: 47px;
            }
            @media screen and (max-width: 480px){
                font-size: 36px;
                line-height: 42px;
            }
             word-wrap: break-word;   
            

            text-align: center;
            color: #0952BC;
            margin-top: 18%;
        }
    }
    &-body{
        &-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 84%;
            margin:-5% auto;
            margin-bottom: 13%;
            background: #FFFFFF;
            box-shadow: 0px 4px 10px 5px #ECF4FF;
            border-radius: 24px;
            &-card{
                width: 95%;
                margin: 20px auto;
                &-header{
                    font-family: Roboto;
                    font-style: normal;
                    font-size: 28px;
                    line-height: 33px;
                    color: #A8A8A8;
                }
                &-text{
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                    margin-top: 16px;
                }
            }
        }
    }
}