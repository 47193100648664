@import "../../../assests/scss/globals.scss";

.alljobs {
  margin-top: 20vh;

  &-header {
    padding: 0 7vw;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;

    &-content {
      width: 90%;
      display: flex;
      justify-content: space-between;

      input {
        width: 45%;
        padding: 1rem;
        border: 1px solid $shade-grey-1;
        border-radius: 0.5rem;
      }

      & .job-dropdown {
        position: relative;
        width: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        background-color: $shade-white;
        border: 1px solid $shade-grey-1;
        border-radius: 0.5rem;

        @include respond-phone {
          width: 100%;
        }
      }
    }
  }

  .textfield__title{
    width: 100%;
  }

  .filter__button{
    width: 152px;
    height: 56px;
    color: $color-button;

  }

  .search__button{
    width: 80%;
    height: 100%;
    background-color: $color-button;

      }
    

  .filter__entity{
    margin: 10px 10px;
  }

  &-filters {
    // margin: 0rem 3.2rem;

    & .filter {
      margin-left: 2rem;
      border: 1px solid $shade-grey-1;
      border-radius: 0.5rem;
      position: relative;
      width: 20%;
      position: relative;
      
      .legend {
        background-color: $shade-white;
        position: absolute;
        top: 0;
        left: 10%;
        transform: translateY(-50%);
        padding: 0 0.3rem;
        color: $color-secondary-2;
      }
      &-content {
        padding: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    & .moreFilterBtn{
      margin-left: 1rem;
    }
    & .reset {
      margin-left: 1rem;
      // margin: 1rem 5rem;
      align-self: center;
      color: $color-button;
      cursor: pointer;
    }
  }
  &-container {
    padding: 0 4vw;
    display: flex;
    width: 100%;
    // margin-left: 2.6rem;

    &-left {
      // width: 50%;

      &-header {
        margin: 0.5rem 0;
        padding: 1rem;
        width: 100%;
        background-color: $color-secondary-1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .toggle {
          cursor: pointer;
          position: relative;
          width: 2rem;
          height: 1rem;
          background-color: $shade-white;
          border: 1px solid $shade-grey-1;
          border-radius: 0.5rem;

          &-circle {
            position: absolute;
            top: -25%;
            left: -10%;
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 50%;
            background-color: $shade-grey-2;
          }
          &-circle {
            position: absolute;
            left: 50%;
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 50%;
            background-color: $shade-grey-2;
            transition: all 0.2s linear;

            &-left {
              left: -10%;
            }
            &-right {
              right: 50%;
              background-color: $color-button;
            }
          }
        }
      }

      &-cards {
        & hr {
          border-bottom: 2px solid $shade-grey-1;
          width: 80%;
          margin: 0 auto;
        }
      }
    }
    &-right {
      width: 70%;
      padding: 2rem;
      // margin-left : 6rem;
    }
  }
}

.down-arrow {
  cursor: pointer;
}
.h-25{
height: 17vh;;
}
.jobcard {
  display: flex;
  cursor: pointer;
  padding: 10px;
  
  &-w-60{
    width: 60% !important;
    color: initial;
  }
  &-left {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    img {
      width: 100%;
      height: auto;
    }
  }

  &-middle {
    width: 55%;
    padding: 0 10px;
    color: $color-accent-2;


    &-name {
      color: $color-accent-2;
      text-transform: uppercase;
    }

    &-location {
      color: $color-secondary-2;
    }
    ul {
      padding-left: 1rem;
      // padding-bottom: 2rem;

      li {
        color: $shade-grey-3;
        list-style-type: circle;
      }
    }
  }

  &-right {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    &-top {
      &-text {
        color: $shade-grey-2;
        margin-bottom: 0.4rem;
      }
      &-skills {
        font-size: 0.5rem;
      }
    }
  }
}

.dropmenu {
  position: absolute;
  background-color: $shade-white;
  // text-align: center;
  border: 1px solid $shade-grey-2;
  border-radius: 0.4rem;
  width: 100%;
  z-index: 15;
  top: 100%;
  left: 0;

  ul {
    padding: 1rem;
    li {
      list-style: none;
      cursor: pointer;
      padding: 0.3rem 0;
    }
  }
}
.job{
  &-header {
    &-right{
      &-buttons{
        display: flex;
      }
    }
  }
}

