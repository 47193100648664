@import "../../assests/scss/globals.scss";

.singlejob {
  padding: 25vh 7vw;
  width: 80%;
  margin: auto;
}

.job {
  width: 100%;

  &-header {
    display: flex;

    &-left {
      max-width: 50%;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-right {
      padding: 0 2rem;

      &-meta {
        display: flex;
        flex-direction: column;

        & .title {
          color: $color-accent-2;
          text-transform: uppercase;
        }

        & .type {
          color: $shade-grey-3;
        }

        & .location {
          color: $color-secondary-2;
        }
      }

      &-list {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 1rem;
        height: 7rem;
        width: 20rem;
        column-gap: 2rem;

        @include respond-phone {
          display: none;
        }

        li {
          width: max-content;
          color: $shade-grey-3;
          flex-grow: 2.5rem;
          // @include respond-tablet {
          //   width: auto;
          //   padding: 0 2rem;
          // }
        }
      }

      &-buttons {
        .btn {
          margin-right: 1rem;
        }
      }
    }
  }

  & .ul-small {
    display: none;

    @include respond-phone {
      display: block;
      display: flex;

      li {
        width: 50%;
      }
    }
  }

  &-desc {
    &-section {
      margin: 2rem 0;

      &-text {
        & ul {
          padding-left: 1.5rem;
        }
      }

      &-tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        @include respond-tablet {
          width: 70%;
        }

        @include respond-phone {
          width: 100%;
        }
      }

      &-btns {
        display: flex;

        @include respond-phone {
          justify-content: space-around;
        }

        @include respond-xs {
          flex-direction: column;
          align-items: center;
        }

        & .btn {
          margin-right: 1rem;

          @include respond-phone {
            margin-top: 1rem;
            width: 40%;
          }

          @include respond-xs {
            margin-right: 0;
            width: 70%;
          }
        }
      }
    }
  }
}

.tag {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 4rem;
  color: $shade-white;
}

.blue-tag {
  background-color: $color-accent-2;
}

.grey-tag {
  background-color: $shade-grey-2;
}