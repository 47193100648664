@import "../../assests/scss/globals.scss";

.btn {
  padding: 1rem 1.9rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary {
  border: none;
  background-color: $color-button;
  color: $shade-white;
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid $color-button;
  color: $color-button;
}

.btn-disabled {
  background-color: $shade-grey-2;
  color: white;
}
.btn-disabled:hover {
  background-color: $shade-grey-2;
  color: white;
}

.btn-accentsecondary {
  background-color: transparent;
  font-weight: 700;
  border: 1px solid $color-accent-2;
  color: $color-accent-2;
}
.btn-secondary:hover {
  background-color: transparent;
  border: 1px solid $color-button;
  color: $color-button;
}
.btn-whiteprimary {
  border: none;
  background-color: $shade-white;
  color: $color-accent-2;
}

.btn-whitesecondary {
  background-color: transparent;
  border: 1px solid $shade-white;
  color: $shade-white;
}

.btn-whiteprimary:hover {
  background-color: $shade-white;
  color: $color-accent-2;
}

.btn-whitesecondary:hover {
  background-color: transparent;
  color: $shade-white;
}

.btn-primeprimary {
  border: none;
  background-color: $color-primary;
  color: $shade-white;
}

.btn-primesecondary {
  background-color: transparent;
  border: 1px solid $color-primary;
  color: $color-primary;
}
