.testimonials-community-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.testimonials-heading-community {
  font-weight: 700;
  color: #166dea;
  font-size: 28px;
  line-height: 33px;
  font-family: Roboto;
}

.testimonials-heading-desc-community {
  font-weight: 400;
  color: #031741;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.7;
  font-family: Roboto;
}

.testimonial-community-college-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
  //   max-width: 100%;
}

.college-images {
  flex-shrink: 1;
  max-width: 100%;
  cursor: pointer;
}

@media (max-width: 1160px) {
  .testimonial-community-college-container {
  }
  .college-images {
    max-width: 140px;
    max-height: 94px;
  }
}

@media (max-width: 560px) {
  .testimonial-community-college-container {
  }
  .college-images {
    max-width: 80px;
    max-height: 53px;
  }
}

@media (max-width: 390px) {
  .testimonials-community-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .college-images {
    max-width: 70px;
    max-height: 46px;
  }
}
