.PhoneInputInput {
	display: block;
	height: 38px;
	padding: 8px 12px;
	/* margin-bottom: 10px; */
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	background-color: #ffffff;
	border: 1px solid #cccccc;
    padding-left: 65px;
}
.PhoneInputCountry {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    padding: 1px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 0 12px;
}
.updatephone {
    font-size: 12px;
    cursor: pointer;
}
