@import "../../../assests/scss/globals.scss";
/* Certificate Main Component Header */

.exam-header,
.exam-main {
  margin: 30px 0;
}

.exam-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-program-btn {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #166dea;
  color: #ffffff;
  padding: 15px 24px;
  border-radius: 5px;

  &:hover {
    color: #ffffff;
    opacity: 0.9;
    cursor: pointer;
  }
}
