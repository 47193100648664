@import "../../../../assests/scss/globals.scss";

.courseMicrosoft {
  position: relative;
  padding: 13vh 7vw 1rem 7vw;
  &-back {
    display: flex;
    z-index: -100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-image: linear-gradient(
        90deg,
        rgba(236, 244, 255, 0.9) 0%,
        rgba(236, 244, 255, 0.5) 78.95%,
        rgba(236, 244, 255, 0.2) 100%
      ),
      url("../../../../assests/images/microsoft.png");
    background-size: cover;
    background-position: top;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    filter: blur(2px);
    @include respond-phone {
      min-height: 100%;
    }

    &-trans {
      width: 100%;
      height: 5%;
      align-self: flex-end;
      background-color: red;
      z-index: 50;
      // background-color: $shade-white;
      opacity: 0.5;
      transform: rotate(-8.78deg);
    }
  }

  &-left {
    width: 75%;

    @include respond-phone {
      width: 100%;
      text-align: center;
    }
    &-heading {
      padding: 1rem 0;
      font-size: 3rem;
      color: $color-primary;
      @include respond-phone {
        font-size: 2.2rem;
      }
    }
    &-subheading {
      width: 75%;
      padding: 1rem 0;
      color: $color-primary;

      @include respond-phone {
        width: 100%;
        text-align: center;
      }
    }
    &-prices {
      padding: 1rem 0;

      .crossed {
        font-size: 1.7rem;
        text-decoration: line-through;
        color: $color-secondary-2;
        font-weight: 500;
        @include respond-tablet {
          font-size: 1.2rem;
        }
      }
      .not-crossed {
        color: $color-accent-2;
        font-size: 1.95rem;
        font-weight: 700;
      }
    }
    &-buttons {
      display: flex;
      flex-direction: row;
      margin-bottom: 5rem;
      .btn {
        margin: 1rem 1rem 1rem 0;

        @include respond-phone {
          min-width: 13rem;
          margin: 1rem 1rem 0.5rem 0;
        }
      }
      @include respond-phone {
        margin-bottom: 2.5rem;
        display: block;
        flex-direction: column;
      }
    }
  }
}
