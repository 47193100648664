@import "../../../../assests/scss/globals.scss";

.component-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-item {
    padding: 5px 20px;
    margin: 0 1px;
    background-color: transparent;
    color: black;

    transition: all 500ms ease-in;

    &:hover {
      cursor: pointer;
      background-color: $color-accent-2;
      color: white;
    }
    &-selected {
      background-color: $color-accent-2;
      color: white;
      cursor: default !important;
    }
  }
}

/* SHIFT */
.shift a {
  position: relative;
  z-index: 1;
}
.shift a:hover {
  color: #91640f;
}
.shift a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: ".";
  color: transparent;
  background: #f1c40f;
  visibility: none;
  opacity: 0;
  z-index: -1;
}
.shift a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

// UploadResultsPage
.results-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.results-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  max-height: calc(100vh - 30vh);
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 4rem;
}

.result-card {
  position: relative;
  width: 100%;
  min-height: 66px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.2rem;
  margin: 5px;
  background-color: transparent;
  border-bottom: 1px solid $shade-grey-1;
  transition: all 500ms ease-in;

  &:hover {
    cursor: pointer;
    background-color: $color-secondary-1;
  }
}
.status-container {
  display: flex;
  align-items: center;
  gap: 2px;
  // outline: 1px solid black;
}
.status-item {
  padding: 10px 12px;
  background-color: transparent;
  color: $shade-grey-2;

  transition: all 500ms ease-in;

  &.passed {
    background-color: #009418;
    color: white;
  }
  &.failed {
    background-color: #ff1717;
    color: white;
  }
  &.absent {
    background-color: #ff1717;
    color: white;
  }
}

.status-text {
  &-passed {
    color: #009418;
  }
  &-failed {
    color: #ff1717;
  }
  &-absent {
    color: #ff1717;
  }
}

.floating-btn-container {
  position: fixed;
  bottom: 5px;
  z-index: 1;
  bottom: 0;
}
