@import "../../../../assests/scss/globals.scss";

.weekCard {
  &-container {
    position: relative;
    display: flex;
    gap: 1rem;
    align-items: center;
    overflow-x: auto;
    margin-top: 2rem;
    width: 100%;
    padding-right: 10rem;
    padding-bottom: 1rem;
  }
  &-single {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 395px;
    max-width: 395px;
    height: 483px;
    @include respond-tablet {
      min-width: 295px;
      max-width: 295px;
    }
    @include respond-phone {
      min-width: 195px;
      max-width: 195px;
    }

    &-header {
      width: 100%;
      // height: 35px;
      // margin: none !important;
      padding: 0.4rem 0;
      background: $color-secondary-1;
      color: $shade-grey-3;
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
    }
    &-list {
      display: flex;
      flex-direction: column;
      overflow: auto;
      &-quiz {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 1rem;
        min-height: 84px;
        max-height: 84px;
        overflow: hidden;
        &-right {
          visibility: hidden;
          display: inline-flex;
          flex-direction: row;
          gap: 0.5rem;
          justify-content: start;
          align-items: center;
        }

        &-middle {
          padding: 0 15px;
          text-align: left;
          font-size: 16px;
          text-overflow: hidden;
          &-header {
            color: $color-accent-2;
            font-weight: bold;
            font-size: 17px;
            line-height: 19px;
            padding-bottom: 5px;
            text-transform: uppercase;
          }
        }
      }
      &-quiz:hover {
        background-color: $color-secondary-1;
      }
    }
  }
}

.btn-add {
  // position: sticky;
  // right: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 100px;
  background-color: $shade-grey-1;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

.add-popover-btn {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  bottom: 30px;
  right: 30px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: $color-accent-1;
  color: white;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  transition: all;
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

.add-popover-content {
  position: relative;
  z-index: 0;
  padding: 0.5rem 1rem;
  &:hover {
    opacity: 1;
    background-color: $color-secondary-1;
    cursor: pointer;
  }
}

.black-coverover {
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
