@import "../../../assests/scss/globals.scss";

.landing {
  min-height: 85vh;
  &-background {
    z-index: -100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &-rectangle {
      background-color: #ecf4ff;
      height: 65vh;
      @include respond-xs {
        height: 70vh;
      }
    }
    &-curved {
      svg {
        width: 100%;
      }
    }
  }
  &-content {
    min-height: 60vh;
    margin: 15vh 7vw 5vh 7vw;
    display: flex;

    @include respond-phone {
      justify-content: center;
    }

    &-left {
      padding: 2rem 0;
      width: 60%;
      min-height: 40vh;
      display: flex;
      flex-direction: column;
      @include respond-tablet {
        width: 90%;
      }

      @include respond-phone {
        width: 90%;
        text-align: center;
      }
      @include respond-xs {
        padding: 0 0;
      }

      &-title {
        margin-top: 2rem;
        font-size: 3.4rem;
        @include respond-tablet {
          font-size: 2.25rem;
        }
        @include respond-phone {
          font-size: 2.1rem;
          transform: translateY(-2rem);
        }
      }
      &-desc {
        margin: 2rem 0;
        width: 70%;
        font-size: 1.25rem;
        @include respond-tablet {
          margin: 0 0;
          font-size: 1rem;
          width: 80%;
        }
        @include respond-phone {
          font-size: 1rem;
          width: 100%;
        }
      }
      &-buttons {
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        .btn {
          width: 13rem;
          margin: 1.5rem 1.5rem 0 0;
          @include respond-phone {
            margin: 1.5rem 0 0 0;
          }
        }
        @include respond-phone {
          justify-content: center;
          align-items: center;
        }
        @include respond-xs {
          margin: 0 0;
        }
      }
    }
    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        width: 100%;
        height: auto;
      }
      @include respond-phone {
        display: none;
      }
    }
  }
}
