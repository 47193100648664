@import "../../../../assests/scss/globals.scss";

.overview {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 5rem;
    @include respond-tablet {
      padding: 4rem 1.5rem;
    }
    @include respond-phone {
      padding: 2rem 15px;
    }
  }
  &-search-input {
    max-width: 800px;
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    border: 2px solid $shade-grey-1;
  }
  &-checkbox {
    &-container {
      max-width: 800px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      margin: 3rem 0;
    }
    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      // grid-template: 1fr 2fr 1fr;
    }
  }
}

.horizontal-line {
  margin: 25px;
  padding: 0;
  background-color: $shade-grey-1;
  border-radius: 50px;
  flex-grow: 1;
  height: 3px;
}
