@import "../../assests/scss/globals.scss";


$small: 600px;

.program{

 &-container{
     display: flex;
     flex-direction: row;
     align-items: center;
     overflow-x: auto;
     width: 100vw;
margin-top: 150px;
    justify-content: center;
        @include respond-phone {
            justify-content: start;
          }

 }

&-paper{
max-width: 363px;
max-height: 517px;
width: 37vw;
height: 80vh;
// background: #FFFFFF;
border: 2px solid #A4A4A4;
box-sizing: border-box;
border-radius: 21px !important;
min-width: 260px;
min-height: 578px;
margin:20px;
}


&-header-light{
max-width: 360px;
width: 36.6vw;
min-width: 260px;
height: 10vh;
border-radius: 21px 21px 0px 0px;
background: $color-accent-2;

&-divider-light{
    height: 7vh;
    width: 100%;
    background: $color-accent-2;
    background-image: linear-gradient(to top left, #ffffff 50%, $color-accent-2 50%);
    }

}

&-header-dark{
    width: 36.6vw;
    max-width: 360px;  
    min-width: 260px;
    height: 10vh;
    border-radius: 21px 21px 0px 0px;
    background: $color-accent-1;

    &-divider-dark{
        height: 7vh;
        width: 100%;
        background: $color-accent-1;
        background-image: linear-gradient(to top left, #ffffff 50%, $color-accent-1 50%);
        }
    }

&-text{
    // position: absolute;
font-weight: 700;
font-size: 16px;
line-height: 19px;
text-transform: uppercase;
color: $shade-white;
transform: translate(20px, 40px);
// margin-left: 40px;
}

&-projects{

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $shade-black;
    
}

&-border{
    color: #414141;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    border-bottom: 1px solid #414141;

    &-dull{
        color: $shade-grey-1;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        border-bottom: 1px solid $shade-grey-1;
    }
}


&-price{
    color: $color-accent-1;
    font-size: 28px;
    font-weight: 700;
    

    &-gst{
        color : $color-secondary-2;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 2px;
    }
}

&-button{
    width: 187px;
    height: 59px;
    border-radius: 5px;
    background-color: $shade-white !important;
    color:  $color-accent-2 !important;
    border: 1px solid $color-accent-2 !important;
    &:hover{
    background-color: $color-button !important;
    color: $shade-white !important;
    }
}



}