@import "../../../../assests/scss/globals.scss";

.project-card {
  position: relative;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  padding: 0 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  &:hover {
    background-color: #ecf4ff;
    .hover-visible {
      visibility: visible;
    }
    .view-btn {
      color: #0952bc;
      border-color: #0952bc;
    }
  }
  &::after {
    content: "";
    width: 80%;
    height: 1px;
    background-color: #c4c4c4;
    position: absolute;
    bottom: 5px;
    left: 0;
  }
}
