@import "../../../assests/scss/globals.scss";

.header {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  padding: 1rem 7vw;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  &-logo img {
    width: 162px;
    height: 54px;
    margin: 0 2rem 0 0;
  }
  &-links {
    width: 45vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: grey;

    a {
      text-decoration: none;
      color: $color-primary;

      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    @include respond-tablet {
      display: none;
    }
  }
  &-buttons {
    display: flex;
    justify-content: space-around;

    .btn {
      margin: 0 0.5rem;
    }
    @include respond-tablet {
      display: none;
    }
  }
  &-phone {
    display: none;

    @include respond-tablet {
      display: block;
    }

    .burger {
      cursor: pointer;
    }
  }
}

.inversed {
  background-color: $shade-black;

  & a {
    color: $shade-white;
  }
}

.mobnav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: $shade-black;
  color: $shade-white;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow-y: hidden;

  &-links {
    padding: 5vh 10vw;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    a {
      font-size: 2rem;
      text-decoration: none;
      color: $shade-white !important;
    }
  }
  &-cross {
    margin: 5vh 8vw;
    position: absolute;
    top: 2vh;
    right: 2vw;
    cursor: pointer;
  }
}
.navcross {
  width: 30px;
  height: auto;
}
