@import "../../../assests/scss/globals.scss";
.business-image {
  max-width: 100%;
  height: auto;
  @include respond-xs {
    width: 266px;
    height: 266px;
  }
}
.business-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  background-color: $color-primary;
  padding: 2rem 5rem 5rem 5rem;
  justify-content: space-around;
  @include respond-phone {
    padding: 5rem 0.6rem;
  }
}
.business-text-container {
  padding-top: 7rem;
  max-width: 550px;
  color: white;

  .btn {
    color: black;
    font-weight: 500;
    cursor: pointer;
    background-color: white;
    width: 195px;
    height: 59px;
    @include respond-tablet {
      width: 235px;
      height: 57px;
    }
    @include respond-phone {
      width: 235px;
      height: 57px;
    }
  }
}
.business-before-heading {
  font-weight: 400;
  font-family: Roboto;
  opacity: 0.7;
  margin: 0px;
}
.business-heading {
  font-weight: 400;
  color: $color-secondary-2 ;
  margin: 0px;
  font-size: 28px;
  line-height: 33px;
}
.business-subheading {
  font-weight: 400;
  line-height: 28px;
  color: $color-secondary-2 ;
  margin-top: 0px;
  margin-bottom: 4rem;
}

.business-description {
  font-weight: 400;
  margin-bottom: 3rem;
  color: #ffffff;
}
// .business-text-container.business-button {

// }

@media (max-width: 1110px) {
  .business-container {
    justify-content: space-between;
  }
  .business-image {
    max-width: 317px;
    max-height: 317px;
  }
  .business-text-container {
    max-width: 430px;
    padding-top: 0px;
    // text-align: center;
  }
}

@media (max-width: 847px) {
  .business-container {
    justify-content: center;
  }
  .business-image {
    max-width: 317px;
    max-height: 317px;
  }
  .business-text-container {
    max-width: 430px;
    padding-top: 0px;
    text-align: center;
  }
}
