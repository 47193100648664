.business-page{
  background-color: #FFFFFF;
}
.businessAlone{
  
    &-container{
      width: 100vw;
      height: calc(100vw * 2202/1366);
      padding: 0;
      margin: 0;
      background-image: url('../../assests/images/business_background.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      display: flex;
      flex-direction: column;
    }
    &-top{
      width: 614px;
      height: auto;
      @media screen and (max-width: 1920px){
        margin-top: 15%;
        margin-left: 10%;
      }
      @media screen and (max-width: 1600px){
        margin-top: 15%;
        margin-left: 10%;
      }
      @media screen and (max-width: 1440px){
        margin-top: 13%;
        margin-left: 10%;
      }
      @media screen and (max-width: 1280px){
        margin-top: 10%;
        margin-left: 10%;
      }
      
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 54px;
        line-height: 63px;
        color: #031741;
        margin-bottom: 30px;
      }
      &-text{
        width: 531px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 137.19%;
        color: #031741;
        opacity: 0.8;
        margin-bottom: 50px;
      }
      &-button{
        width: 183px;
        height: 59px;
        background: #0952BC;
        border-radius: 5px;
        margin: 16px 0px;
        padding: 20px 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        cursor: pointer;
        text-transform: uppercase;
        color: #FFFFFF;
      }
      &-button:hover{
        color: #FFFFFF;
      }
    }
  
    &-hiring{
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 1920px){
        margin-top: 10%;
      }
      @media screen and (max-width: 1600px){
        margin-top: 14%;
      }
      @media screen and (max-width: 1440px){
        margin-top: 14%;
      }
      @media screen and (max-width: 1366px){
        margin-top: 12%;
      }
      @media screen and (max-width: 1280px){
        margin-top: 13%;
      }
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        color: #166DEA;
      }
  
      &-subheader{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #031741;
        opacity: 0.5;
        margin-top: 0%;
        margin-bottom: 30px;
      }

      // &-a{
      //   width:10%;
      //   padding-bottom: 10px;
      //   min-height:auto;
      // }
  
      &-container{
        width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 30px;
        &-card{
          margin: 2px 5px;
        }
        &-image{
          margin: 10px 5px;
          @media screen and (max-width: 1600px){
            width: 200px;
            height: 120px;
          }
          @media screen and (max-width: 1280px){
            width: 180px;
            height: 100px;
          }
        }
      }
    }
  
    &-options{
      width: 516px;
      height: auto;
      @media screen and (max-width: 1920px){
        margin-top: 18%;
        margin-left: 12%;
      }
      @media screen and (max-width: 1600px){
        margin-top: 15%;
        margin-left: 12%;
      }
      @media screen and (max-width: 1440px){
        margin-top: 10%;
        margin-left: 12%;
      }
      @media screen and (max-width: 1366px){
        margin-top: 8%;
        margin-left: 12%;
      }
      @media screen and (max-width: 1280px){
        margin-top: 8%;
        margin-left: 12%;
      }
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        color: #031741;
        margin-bottom: 40px;
      }
      &-text{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #6C6C6C;
        margin-bottom: 40px;
      }
      &-button{
        width: 183px;
        height: 59px;
        background: #0952BC;
        border-radius: 5px;
        margin: 16px 0px;
        padding: 20px 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        cursor: pointer;
        text-transform: uppercase;
        color: #FFFFFF;
      }
      &-button:hover{
        color: #FFFFFF;
      }
    }
  
    &-out{
      width: 561px;
      height: auto;
  
      @media screen and (max-width: 1920px){
        margin-top: 18%;
        margin-left: 52%;
      }
      @media screen and (max-width: 1600px){
        margin-top: 14%;
        margin-left: 50%;
      }
      @media screen and (max-width: 1440px){
        margin-top: 14%;
        margin-left: 50%;
      }
      @media screen and (max-width: 1280px){
        margin-top: 10%;
        margin-left: 50%;
      }
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        color: #031741;
        margin-bottom: 40px;
      }
      &-text{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #6C6C6C;
        margin-bottom: 40px;
      }
      &-button{
        width: 183px;
        height: 59px;
        background: #0952BC;
        border-radius: 5px;
        margin: 16px 0px;
        padding: 20px 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        cursor: pointer;
        text-transform: uppercase;
        color: #FFFFFF;
      }
      &-button:hover{
        color: #FFFFFF;
      }
    }

    &-footer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #FFFFFF;
      &-image-1{
        width: 80%;
        margin: auto;
        margin-bottom: 20px;
      }
    }
  }
  
  @media screen and (max-width: 960px){
    .businessAlone{
    &-container{
      background-image: url('../../assests/images/business_background_960.png');
      width: 100vw;
      height: calc(100vw * 3527/960);
    }
  
    &-top{
      width:531px;
      margin-left: 7%;
      margin-top: 13%;
      
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 47px;
        color: #031741;
      }
    }
    &-hiring{
      margin-top: 20%;
      
      &-container{
        width: 100vw;
        &-card{
        width: 200px;
        height: 100px;
      }
        &-image{
          width: 200px;
          height: 100px;
      }
    }
    }
  
    &-options{
      width: 427px;
      margin-top: 22%;
      margin-right: 40%;
    }
    &-out{
      width: 427px;
      margin-top: 22%;
    }
  }
  }
  
  @media screen and (max-width: 480px){
    .businessAlone{
    &-container{
      background-image: url('../../assests/images/business_background_480.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100vw;
      height:  5103px;
    }
  
    &-top{
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0%;
      margin-top: 96px;
      &-header{
        width: 350px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 47px;
        color: #031741;
        word-wrap: break-word;
        text-align: center;

        // @media screen and (max-width: 416px) {
        //     width: 90vw;
        //     font-family: Roboto;
        //     font-style: normal;
        //     font-weight: bold;
        //     font-size: 35px;
        //     line-height: 40px;
        //     color: #031741;
        //     word-wrap: break-word;
        //     text-align: center;
        // }
      }
      &-text{
        width:350px;
        text-align: center;
      //   @media screen and (max-width: 416px) {
      //     font-family: Roboto;
      //     font-style: normal;
      //     font-weight: normal;
      //     font-size: 17px;
      //     line-height: 20px;
      //     color: #031741;
      //     opacity: 0.8;
      //     margin-bottom: 35px;
      // }
        
      }
    }
    &-hiring{
      margin-top: 75px;
      margin-left: 0;
      // @media screen and (max-width: 375px){
      //   margin-top: 5%;
      // }
      &-container{
        width: 350px;
        &-card{
        width: 100px;
        height: 50px;
      }
        &-image{
          width: 100px;
          height: 50px;
      }
    }
    }
  
    &-options{
      width: 100vw;
      // @media screen and (max-width: 375px){
      //   margin-top: 28%;
      // }
      margin-left: 0;
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
        
      

      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        color: #166DEA;
        margin-bottom: 480px;
        // @media screen and (max-width: 450px){
        //   margin-top: -11%;
        // }
        // @media screen and (max-width: 416px){
        //   margin-top: -15%;
        // }
        // @media screen and (max-width: 375px){
        //   margin-top: -12%;
        // }
        // @media screen and (max-width: 360px){
        //   margin-top: -20%;
        // }
        
      }
      &-text{
        width: 350px;
        text-align: center;
        margin-bottom: 50px;
      }
    }
    &-out{
      width: 100vw;
      margin-top: 170px;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 
      
      // @media screen and (max-width: 375px){
      //   margin-top: 20%;
      // }
      
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        color: #166DEA;
        margin-bottom: 480px;
        // @media screen and (max-width: 450px){
        //   margin-top: -5%;
        // }
        // @media screen and (max-width: 416px){
        //   margin-bottom: 88%;
        //   margin-top: -10%;
        // }
        // @media screen and (max-width: 375px){
        //   margin-bottom: 88%;
        //   margin-top: -5% !important;
        // }
        // @media screen and (max-width: 360px){
        //   margin-bottom: 88%;
        //   margin-top: -10% !important;
        // }
          
      }
      &-text{
        width: 350px;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }
  }
  
  @media screen and (max-width: 360px){
    .businessAlone{
    &-container{
      background-image: url('../../assests/images/business_background_480.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100vw;
      height: calc(360px * 5103/480); //5103px;
    }
  
    &-top{
      width: 280px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin : 0 auto;
      margin-top: 20%;
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 40px;
        text-align: center;
      }
      &-text{
        width:90vw;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
      }
    }
    &-hiring{
      margin-top: 50px;
      &-subheader{
        width: 280px;
        font-size: 16px;
        margin-bottom: 0px;
      }
      &-container{
        width: auto;
        &-card{
        width: 100px;
        height: 50px;
      }
        &-image{
          width: 100px;
          height: 50px;
      }
    }
    }
  
    &-options{
      width: 100vw;
      margin-top: 30px;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        color: #166DEA;
        margin-bottom: 100%;
      }
      &-text{
        width: 90vw;
        text-align: center;
        margin-bottom: 60px;
        font-size: 14px;
      }
    }
    &-out{
      width: 100vw;
      margin-top: 50px;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;   
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        color: #166DEA;
        margin-bottom: 305px;
      }
      &-text{
        width: 90vw;
        text-align: center;
        font-size: 14px;
        margin-bottom: 0px;
      }
    }
  }
  }
  @media screen and (max-width: 320px){
    .businessAlone{
    &-container{
      background-image: url('../../assests/images/business_background_320.png');
      width: 100vw;
      height: calc(100vw * 4975/320);
    }
  
    &-top{
      width: 280px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin : 0 auto;
      margin-top: 20%;
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 47px;
        text-align: center;
      }
      &-text{
        width:90vw;
        text-align: center;
  
      }
    }
    &-hiring{
      margin-top: 14%;
      &-subheader{
        width: 280px;
      }
      &-container{
        width: 98vw;
        &-card{
        width: 100px;
        height: 50px;
      }
        &-image{
          width: 100px;
          height: 50px;
      }
    }
    }
  
    &-options{
      width: 100vw;
      margin-top: 25%;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        color: #166DEA;
        margin-bottom: 100%;
      }
      &-text{
        width: 90vw;
        text-align: center;
        margin-bottom: 60px;
      }
    }
    &-out{
      width: 100vw;
      margin-top: 60%;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;   
      &-header{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        color: #166DEA;
        margin-bottom: 100%;
      }
      &-text{
        width: 90vw;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }
  }
