@import "../../assests/scss/globals.scss";

.jobs {


  &-search-button {
    width: 228px;
    height: 59px;
    background-color: $color-button !important;
  }

  &-text {
    background-color: $shade-white;
    width: 100%;
    border-radius: 5px;
  }

  &-header {
    padding: 0 7vw;
    width: 100%;
    height: 80vh;
    background-image: linear-gradient(180deg,
        #ecf4ff 0%,
        rgba(236, 244, 255, 0) 33.78%,
        rgba(255, 255, 255, 0.61) 59.69%,
        #ffffff 100%),
      url("../../assests/images/jobs.png");
    background-size: cover;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
      text-align: center;
      width: 60%;

      @include respond-tablet {
        width: 90%;
      }

      @include respond-phone {
        width: 100%;
      }

      & .btn {
        margin: auto;
        margin-top: 0.5rem;
        width: 30%;

        @include respond-phone {
          width: 60%;
        }

        @include respond-xs {
          width: 90%;
        }
      }

      &-inputs {
        width: 100%;
      }

      &-fields {
        display: flex;
        justify-content: space-between;

        @include respond-phone {
          flex-direction: column;
        }

        input {
          width: 55%;
          padding: 1rem;
          border: 1px solid $shade-grey-1;
          border-radius: 0.5rem;
          margin: 0.5rem 0;

          @include respond-phone {
            width: 100%;
          }
        }

        &-inputs {
          width: 100%;
        }

        & .job-dropdown {
          position: relative;
          width: 40%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          background-color: $shade-white;
          border: 1px solid $shade-grey-1;
          border-radius: 0.5rem;
          margin: 0.5rem 0;

          @include respond-phone {
            width: 100%;
          }
        }
      }
    }
  }

  &-cards {
    padding: 0 7vw;
    margin-top: 3rem;
    margin-bottom: 12rem;
  }
}


.filter__dialog {
  color: $color-button !important;
}

.resume {

  &-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 63px;
    color: #000000;
    text-align: center;
    font-size: calc(1.5vw + 1.5vh + 1vmin);
    margin: 60px 0px;
  }

  &-content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6C6C6C;
    font-size: calc(1vw + 1vh + 1vmin);
    margin: 60px 0px;


  }

  &-button {
    width: 250px;
    height: 50px;
    background-color: #0952BC !important;
    color: white !important;
    border-radius: 5px;
    font-size: 20px !important;
  }
}






@keyframes fill {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(135deg);
  }
}

.resumeChecker {

  &-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    font-size: calc(1vw + 1vh + 0.3vmin);
    line-height: 33px;
    color: #031741;
  }

  &-fields {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    text-align: start;
    color: #031741;
    font-size: calc(1vw + 1vh + 0vmin);

  }

  &-reason {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: left;
  }
}

.score-box {
  color: #414141;
  border: 1.5px solid #166DEA;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px;
  max-width: 350px;
  // height: 30vh;
  size: 16px;
  line-height: 19.95px;
}

.web-text {
  color: #031741;
}




// new


circle {
  transition: all 0.7s ease-in-out;
}