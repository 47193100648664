@import "../../../assests/scss/globals.scss";

.Intensive {
  padding: 1rem 7vw 1rem 7vw;
  color: $color-primary;
  @include respond-phone {
    padding-top: 5rem;
  }

  &-seperated {
    font-size: 1.4rem;
    padding: 0 0 0 45%;
    transform: translateY(5rem);

    @include respond-phone {
      padding: 0;
      text-align: center;
      transform: translateY(0rem);
    }
  }
  &-container {
    display: flex;
    flex-direction: row-reverse;
    @include respond-tablet {
      padding-bottom: 5rem;
    }

    &-left {
      padding-top: 5rem;
      max-width: 55%;
      padding-right: 3rem;
      &-title {
        font-size: 1.4rem;
      }
      &-desc {
        color: $color-secondary-2;
        margin: 2rem 0;
      }
      @include respond-phone {
        padding-top: 0rem;
        max-width: 100%;
        padding-right: 0;
      }
    }
    &-right {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-items: center;

      img {
        max-width: 95%;
        height: auto;
        padding: 3rem 3rem 0rem 3rem;

        @include respond-phone {
          padding: 0;
          max-width: 60%;
        }
      }
    }
    @include respond-phone {
      flex-direction: column-reverse;
      text-align: center;
    }
  }
}
