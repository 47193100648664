@import "../../../assests/scss/globals.scss";

.sublanding {
  padding: 5rem 0 0 0;
  width: 100%;
  height: auto;
  color: $shade-white;
  overflow: hidden;

  &-header {
    width: 100%;
    text-align: center;

    &-title {
      font-size: 2rem;
      @include respond-xs {
        font-size: 1.9rem;
      }
    }
    &-subtitle {
      font-size: 1rem;
      padding: 0 2rem;
      color: rgba($shade-white, 0.7);
    }
    @include respond-phone {
      width: 95%;
      margin: auto;
    }
  }
  &-cards {
    padding: 3rem 4rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow: auto;
    @include respond-xs {
      padding: 5rem 0;
    }

    &-card {
      margin-top: 1rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      width: 22vw;

      @include respond-tablet {
        width: 30vw;
      }
      @include respond-phone {
        width: 60vw;
      }
      @include respond-xs {
        width: 90vw;
      }

      &-img {
        text-align: center;
      }
      &-title {
        margin-top: 1rem;
        font-size: 1.2em;
      }
      &-desc {
        font-size: 1.2em;
        padding: 0;
        font-weight: 400;
        color: rgba($color-secondary-1, 0.7);
      }
    }
  }
}
